import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the metrics of Contacts aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classified Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of AI Classified Contacts`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of contacts classified by an Artificial Intelligence bot.`}<br />{`Number of contacts * 100 / Total number of contacts`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Attributed conversion value`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Attributed conversion value`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Attributed conversion value`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Attributed conversion value`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Bot Name`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Bot Name`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Callbacks`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Callbacks`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Callbacks canceled`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Callbacks canceled`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Classified contacts`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Classified contacts`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Contact requests`}</li><li><b>{`# - Count`}</b>{`: Count of Contact requests`}</li><li><b>{`# - Count`}</b>{`: Count of Contact requests`}</li><li><b>{`DAvg - Daily average`}</b>{`: Daily average of Contact requests`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contact requests`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contact requests`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contact requests`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Contacts`}</li><li><b>{`DAvg - Daily average`}</b>{`: Daily average of Contacts`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contacts`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Contacts with cobrowse`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contacts with cobrowse`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Credits`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Credits`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Credits`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Credits`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Credits`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Duration`}</li><li><b>{`µ - Average`}</b>{`: Average of Duration`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Duration`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Duration`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Duration`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Has transcript?`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Has transcript?`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Influenced conversions`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Influenced conversions`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Influenced conversions`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Influenced conversions`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Pending Callbacks`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Pending Callbacks`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`∑ - Sum`}</b>{`: Sum of RGUs`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Sales`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Short calls`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Short calls`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Wait time`}</li><li><b>{`µ - Average`}</b>{`: Average of Wait time`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Wait time`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Wait time`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Wait time`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Wait time`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Chat`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Duration (Click2Chat)`}</li><li><b>{`µ - Average`}</b>{`: Average of Duration (Click2Chat)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Duration (Click2Chat)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Duration (Click2Chat)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Duration (Click2Chat)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration (Click2Chat)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Live Chat`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Live Chat`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Nodes used`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Nodes used`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Nodes used`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Number of interactions`}</li><li><b>{`# - Count`}</b>{`: Count of Number of interactions`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Number of interactions`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Number of interactions`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success (Click2Chat)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success (Click2Chat)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Time on node`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Time on node`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Time on node`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Transfer Requests`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Transfer Requests`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Transfers with Success`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Transfers with Success`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Transfers without Success`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Transfers without Success`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess (Click2Chat)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess (Click2Chat)`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Form`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Form`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Form`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Video`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success (VideoCall)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success (VideoCall)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess (VideoCall)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess (VideoCall)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of VideoCall`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of VideoCall`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Voice`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Abandonment on queue (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Abandonment on queue (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Abandonment on queue (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Abandonment on queue (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Abandonment on queue (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Abandonment on queue (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Call waiting time`}</li><li><b>{`µ - Average`}</b>{`: Average of Call waiting time`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Call waiting time`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Call waiting time`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Call waiting time`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Call waiting time`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Call-center did not answer (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Call-center did not answer (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Classified contacts (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Classified contacts (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Click2Call`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Click2Call`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Client did not answer (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Client did not answer (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Client did not answer (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Client did not answer (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Contact center did not answer (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contact center did not answer (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Contact center did not answer (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Contact center did not answer (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Credits (Click2Call)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Credits (Click2Call)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Credits (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Credits (Click2Call)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Credits (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Credits (Inbound)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Credits (Inbound)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Credits (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Credits (Inbound)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Credits (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Credits (Outbound)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Credits (Outbound)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Credits (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Credits (Outbound)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Credits (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Duration (Click2Call)`}</li><li><b>{`µ - Average`}</b>{`: Average of Duration (Click2Call)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Duration (Click2Call)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Duration (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Duration (Click2Call)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Duration (Inbound)`}</li><li><b>{`µ - Average`}</b>{`: Average of Duration (Inbound)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Duration (Inbound)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Duration (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Duration (Inbound)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Duration (Outbound)`}</li><li><b>{`µ - Average`}</b>{`: Average of Duration (Outbound)`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Duration (Outbound)`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Duration (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Duration (Outbound)`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Human validation`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Human validation`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Identified contacts (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Identified contacts (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Inbound`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Inbound`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Invalid-Client (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Invalid-Client (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Invalid-Contact Center (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Invalid-Contact Center (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Outbound`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Outbound`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Short calls (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Short calls (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Short calls (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Short calls (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Success classified (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Success classified (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Time until call`}</li><li><b>{`µ - Average`}</b>{`: Average of Time until call`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Time until call`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Time until call`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Time until call`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Time until call`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Total call duration`}</li><li><b>{`µ - Average`}</b>{`: Average of Total call duration`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Total call duration`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Total call duration`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Total call duration`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Total call duration`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess (Inbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess (Inbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess (Outbound)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess (Outbound)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess-Silence (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess-Silence (Click2Call)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unsuccess-Voicemail (Click2Call)`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Unsuccess-Voicemail (Click2Call)`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      