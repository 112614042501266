import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following table lists the data fields available in all of Contact Center and Analytics Studio listing panels, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Sources`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category > Sub-category`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the page element that contains the window from which the customer that visited the site abandoned the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the active element that contains the window from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the campaign.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External Campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, identifier of external campaign from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, name of external campaign from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, type of external campaign from which the customer requested the contact. For example, a Facebook campaign.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the names of the external campaigns that handled the customer until the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the identifiers of the external campaigns that handled the customer until the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the placeholder that contains the window from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the placeholder that contains the window from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the skill of the operator that handled the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the component of the onsite campaign from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the component of the onsite campaign from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts to reach the customer in a contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total duration of the contact represented as an interval. For example, `}<inlineCode parentName="td">{`16-30`}</inlineCode>{` minutes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer's evaluation of the operator who handled the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DTMF Success Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The percentage of DTMF signals that the IVR successfully detects and processes. For example, `}<inlineCode parentName="td">{`100%`}</inlineCode>{` or `}<inlineCode parentName="td">{`72%`}</inlineCode>{`.`}<br />{`A `}<a parentName="td" {...{
              "href": "/glossary/#dtmf-success-rate"
            }}>{`DTMF Success Rate`}</a>{` of 72% means that in every 100 times that the customer pressed a number to navigate a menu, 72 were corrected pressed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVR`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`For IVRs, duration of the interaction in the IVR. If the IVR forwards the interaction to an operator, this duration doesn't include time spent with the operator.`}</li><li>{`For Leads, sum of the durations of all the contacts associated with the lead.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration in represented in an interval. For example, `}<inlineCode parentName="td">{`31-45`}</inlineCode>{` minutes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the last page that the customer browsed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information retrieved from the contact form.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwarded to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number to where the IVR forwarded the interaction.`}<br /><br />{`If the `}<em parentName="td">{`IVR status`}</em>{` is `}<inlineCode parentName="td">{`Forwarded to sklill`}</inlineCode>{`, `}<em parentName="td">{`Forwarded to`}</em>{` contains the id of the skill to which the interaction was forwarded.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has IVR?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes: An IVR handled the customer during some period of the interaction.`}</li><li>{`No: The interaction with the customer was never routed to the IVR.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`123456`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`Support`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of the call while on IVR. If the call is routed to an operator, this duration does not include the time spent with the operator.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction ended. For example, `}<inlineCode parentName="td">{`2025-01-09 14:42:06`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction started. For example, `}<inlineCode parentName="td">{`2025-01-09 14:41:36`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`None: Engagement Cloud did not classified the interaction. `}</li><li><strong parentName="td">{`Disconnected`}</strong>{`: The customer disconnected the interaction while in the IVS.`}</li><li><strong parentName="td">{`Finished`}</strong>{`: The customer or the IVR finished the interaction.`}</li><li><strong parentName="td">{`Forwarded to skill`}</strong>{`: The IVR forwarded the interaction to an operator.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction day of the week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the week of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction month (MM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of the last interaction of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the last node that handled the interaction. For example, node `}<inlineCode parentName="td">{`18`}</inlineCode>{` or `}<inlineCode parentName="td">{`forward_to_operator`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Source chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the page where the visitor start navigating the website. For example, `}<inlineCode parentName="td">{`http://documentation.coremedia.com`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Target chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total of processed nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of nodes that handled the interaction. For example, `}<inlineCode parentName="td">{`14`}</inlineCode>{`.`}<br />{`If you click on a value on this column, the contact window opens.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instant in the interaction that triggers the IVR. For example, `}<inlineCode parentName="td">{`Beginning of the call`}</inlineCode>{` or `}<inlineCode parentName="td">{`Operator transfer`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor record`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Record of the visitor containing name and email.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/voice-recognition-accuracy"
            }}>{`Rate of successful voice recognition`}</a>{`. For example, in a voice command, an accuracy of `}<inlineCode parentName="td">{`100%`}</inlineCode>{` means that the IVR could understand all words that the customer said. An accuracy of `}<inlineCode parentName="td">{`50%`}</inlineCode>{` means that out of 4 words the IVR could only understand and process 2 words.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center) (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the con`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue. For example, while waiting for the operator after IVR.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue represented in intervals.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited after requested to be contacted represented in intervals.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# Bundles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/#bundles"
            }}>{`Packages that include multiple RGUs`}</a>{`. Number of packages sold during the `}<a parentName="td" {...{
              "href": "/glossary/#contact"
            }}>{`contact`}</a>{` with the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# RGUS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/#rgus"
            }}>{`Revenue Generating Unit`}</a>{`. Number of products or services sold during the `}<a parentName="td" {...{
              "href": "/glossary/#contact"
            }}>{`contact`}</a>{` with the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date & time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[(YYYYMMDD)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year, month, and day when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Day (DD)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Hour (HH)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Month (MM)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Quarter]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Week]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Weekday]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Year (YYYY)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date `}{`[Year and month (YYYYMM)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification month and week and day`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month, week, and day when the contact was classified.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Sale?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes: The contact ended with a sale.`}</li><li>{`No: The contact ended without sales.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Value of the sale. It can be expressed in monetary units like € or $ or in RGUs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the classification of the contact after finalization. One of the following:`}<ul><li>{`Classified by Operator`}</li><li>{`Not Classified`}</li><li>{`Not Classified - Transferred`}</li><li>{`Not Classified - Expired Time`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of credits attributed to the operator that handled the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnection origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For chat interactions, entity responsible for ending the communication. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for chat interactions with the customer. One of the following:`}<ul><li>{`Visitor disconnection`}</li><li>{`Ended by operator`}</li><li>{`Visitor inactivity`}</li><li>{`Ended by visitor`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause (contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for voice interactions with the contact center. One of the following:`}<ul><li>{`Completed transfer`}</li><li>{`Failed transfer`}</li><li>{`Canceled transfer`}</li><li>{`Busy`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has conversion?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - If the contact ends with a conversion.`}</li><li>{`No - If the contact doesn't end with a conversion.`}</li></ul>{`Note that the definition of conversion depends on the campaign purpose. For example, the customer bought a product or the customer filled in a survey giving information to the campaign.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is it classified?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - Engagement Cloud automatically classified the contact or the operator manually classified the contact.`}</li><li>{`No - Engagement Cloud failed to classify the contact or there is no classification available.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code that the client defined to wrapup the contact. For example, `}<inlineCode parentName="td">{`Sale`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Contact finalization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact started. For example, `}<inlineCode parentName="td">{`2025-01-15`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`If the customer calls the contact, this represents the time when the operator or the IVR answers the call until the call ends.`}</li><li>{`If the contact center calls a customer, this represents the time from when the customer answers the call until it ends.`}</li><li>{`If the `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, this represents the time when the customer receives the first message until the chat ends due to one of the following reasons:`}<ul><li>{`The operator ended the chat.`}</li><li>{`The chat was inactive.`}</li><li>{`Lack of persistence.`}</li></ul></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration of the contact from the time the interaction reaches the contact center until it finishes. For voice calls, it also includes time spend in the IVR.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the contact started.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of initial scheduling`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first scheduling to contact the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact started.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, date when the contact hung up and ended the interaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact started.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of 1st attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt to contact the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt that the customer answered.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time from the beginning of the contact until the customer reaches an operator.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while on hold or, while waiting for information from the operator, or while waiting to reach an operator after an IVR transfer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time while the contact is in queue.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time since the costumer requested the contact until the first attempt to contact the customer happened.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week when the contact started`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the weekday when the contact started. One of the following:`}<ul><li>{`1 for Monday`}</li><li>{`2 for Tuesday`}</li><li>{`3 for Wednesday`}</li><li>{`4 for Thursday`}</li><li>{`5 for Friday`}</li><li>{`6 for Saturday`}</li><li>{`7 for Sunday`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the contact started. For example, `}<inlineCode parentName="td">{`January 2025`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact started. For example, `}<inlineCode parentName="td">{`2025`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Dates and times`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caller ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For inbound voice contacts, phone number of the calling customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel of the interaction with the customer. One of the following:`}<ul><li>{`Default`}</li><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact hangup origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Who ended the interaction. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li><li>{`System`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact initial skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill of the first operator that handle the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferred language for inter with the customer. For example, `}<inlineCode parentName="td">{`Portuguese`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP from which the customer requested the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL from where the visitor requested the conctact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Success`}</li><li>{`Un-success`}</li></ul>{`Note that the `}<em parentName="td">{`Status per contact type`}</em>{` field contains details about each contact status.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Voice`}</inlineCode>{`, one of the following: `}<ul><li><strong parentName="td">{`Click2Call`}</strong>{`: Contact created from a call that the customer requests using Click2Call.`}</li><li><strong parentName="td">{`Outbound`}</strong>{`: Contact created from a call to a phone number of a customer in a contact list without the intervention of an operator. For example, to play a promotional recording.`}</li><li><strong parentName="td">{`Callcenter Outbound`}</strong>{`: Contact created from a call to a phone number of a customer in a contact list that the CoreMedia Contact Center delivers to an operator, possibly, after playing a recording. For example, a call that the CoreMedia Contact Center delivers to an operator to allow the customer to buy a product after hearing the promotional recording.`}</li><li><strong parentName="td">{`Inbound`}</strong>{`: Contact created from a call that the customer makes to an operator.`}</li><li><strong parentName="td">{`Calltracker`}</strong>{`: Contact created from an inbound call with `}<a parentName="td" {...{
                      "href": "/glossary/#calltracker"
                    }}>{`calltracker`}</a>{`.`}</li><li><strong parentName="td">{`API`}</strong>{`: An API endpoint created the contact.`}</li><li><strong parentName="td">{`Workflow`}</strong>{`: A workflow function created the contact.`}</li><li><strong parentName="td">{`Active Manual`}</strong>{`: Contact created from an outbound call that the operator made manually.`}</li><li><strong parentName="td">{`Active Schedule`}</strong>{`: Contact created from an outbound call previously scheduled.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Video Broadcast`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Video broadcast`}</strong>{`: Outbound call that plays a video to the customer.`}</li><li><strong parentName="td">{`Video2Call`}</strong>{`: Voice call with video.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` =  `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Live Chat`}</strong>{`: Contact created from a chat conversation with a live operator.`}</li><li><strong parentName="td">{`Chatbot`}</strong>{`: Contact created from an interaction with a chatbot.`}</li></ul></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Voice`}</strong>{`: Contact where the customer talks with an operator through a voice call. The voice call can be inbound, outbound, or Click2Call.`}</li><li><strong parentName="td">{`Video Broadcast`}</strong>{`: Voice calls that include video. `}</li><li><strong parentName="td">{`Chat`}</strong>{`: Contacts where customers use messaging to communicate with the contact center.`}</li><li><strong parentName="td">{`Forms`}</strong>{`: Voice, video, or chat contacts that the customer started using a form.`}</li><li><strong parentName="td">{`External`}</strong>{`: Contacts where the operator talks with the customer outside the CoreMedia Contact Center. After finishing, the operator creates the contact manually in the CoreMedia Contact Center.`}</li></ul>{`Note that the `}<em parentName="td">{`Contact Subtype`}</em>{` field contains details about each contact type.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact unique identifier according to the contact type. For example, a voice contact can have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{` and a chat contact can also have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia Main ID (Ticket ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact unique identifier.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation reason`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination of the interaction. For example, `}<inlineCode parentName="td">{`Click2Call`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of the interaction. One of the following:`}<ul><li>{`None`}</li><li>{`Inbound`}</li><li>{`Outbound`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the operator that handled the interaction with the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Origin contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the contact that cause the creation of this contact. For example, the visitor ends an interaction with an operator and the operator transfers the interaction to another department. In this case, the first operator ends the contact and a new contact is created.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Source`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source of the interaction. For example, `}<inlineCode parentName="td">{`Phone`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status per contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{` If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Success`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Normal`}</strong>{`:  Every contact from the customer that reaches an operator.`}</li><li><strong parentName="td">{`Finished on IVR`}</strong>{`: Contacts where the customer is able to solve the problem or complete the intended task using just the IVR. For example, a call to the electric company to communicate monthly consumptions.`}</li><li><strong parentName="td">{`Template sent`}</strong>{`: Contacts that end with a template sent to WhatsApp.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Un-Success`}</inlineCode>{`, one of the following:`}<ul><li>{`Contact Center did not answer`}</li><li>{`Customer did answer, but the Contact Center didn't answer`}</li><li>{`Abandonment in queue`}</li><li>{`Scheduled contact`}</li><li>{`Customer did not answer`}</li><li>{`Disconnected on IVR`}</li><li>{`Contact Center didn't answer after IVR success`}</li><li>{`Impossible to sent`}</li></ul></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sub status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact sub status. For example, `}<inlineCode parentName="td">{`Normal`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time spent in the IVR node.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, one of the following:`}<ul><li>{`Inbound`}</li><li>{`Outbound`}</li><li>{`API`}</li><li>{`Calltracker`}</li><li>{`Click2Call`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the customer. For example, `}<inlineCode parentName="td">{`Portugal`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Location information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Location information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the city of the customer. For example, `}<inlineCode parentName="td">{`Lisbon`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Location information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the device of the operator. For example, `}<inlineCode parentName="td">{`Apple MacBook`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Automatic classification of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Summary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Summary of the contact.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Bidireccional Video?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used bidirectional video.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use bidirectional video.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Video?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used video.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use video.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has cobrowse?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used cobrowsing.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use  cobrowsing.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The interaction of the contact was recorded.`}</li><li><strong parentName="td">{`No`}</strong>{`: The interaction of the contact was not recorded.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The interaction of the contact a transcript.`}</li><li><strong parentName="td">{`No`}</strong>{`: The interaction of the contact doesn't have a transcription.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Have on-demand recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact has on-demand recording associated.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact doesn't have an on-demand recording associated.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording On-demand`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sentiment Analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Other services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Center number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has triggered a schedule?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is short call?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction sender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator identification method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice actions sequence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information > Specific by contact type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A/B Test ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A/B Test Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia Element ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia Element Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Variant ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Variant Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the cookie of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer information > Customer identification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor unique identifier.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer information > Customer identification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of visitor identification. For example, `}<inlineCode parentName="td">{`2031253224`}</inlineCode>{`.`}<br />{`One of the following:`}<ul><li><strong parentName="td">{`FId`}</strong>{`: The Digital Experience Platform generated the visitor identifier.`}</li><li><strong parentName="td">{`Cookie ID`}</strong>{`: Digital Experience Platform read the visitor ID from a Cookies.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer information > Customer identification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer information > Segments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer information > Segments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of the visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week of visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the weekday of the visit. One of the following:`}<ul><li>{`Sunday`}</li><li>{`Monday`}</li><li>{`Tuesday`}</li><li>{`Wednesday`}</li><li>{`Thursday`}</li><li>{`Friday`}</li><li>{`Saturday`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of the visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood detected and recorded for the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General NPS information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Net Promoter Score (NPS) value, typically, is a value between 0 and 10, where 0 means that the customer is not happy and will not recommend the business to anyone, and 10 means that the customer is very happy and will recommend the business to everyone.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General NPS information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date of the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date of the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date of the lead. For example, `}<inlineCode parentName="td">{`2025-January-18`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of `}<a parentName="td" {...{
              "href": "/glossary/#rgus"
            }}>{`RGUS`}</a>{` that were realized in the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: If the lead had a sale.`}</li><li><strong parentName="td">{`No`}</strong>{`: If the lead did not had a sale.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the lead resulted in a sale, contains the date when the sale was completed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total associated contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of contacts associated with the lead.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of device. One of the following:`}<ul><li>{`Smartphone`}</li><li>{`Tablet`}</li><li>{`Desktop`}</li><li>{`Console`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > Device information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FID (external ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`External identifier of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain  of the page that contains the link that the visitor used to open the page. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sequence of terms that the visitor used in the last search. For example, `}<inlineCode parentName="td">{`kitchen knive`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP of the session.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL of the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session information > General`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`City`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the page that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{` or `}<inlineCode parentName="td">{`https://support.coremedia.com`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer URL Hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Visitors`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP address of the visitor. For example, `}<inlineCode parentName="td">{`881.28.312.791`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Secure Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL Hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor info`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor Segments IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor info`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first recorded contact of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first recorded access of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the visitor that Digital Experience Platform attributes to the visitor using visitor information like IP address and browser type, among others.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the most recent recorded contact of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the last access of the visitor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of te following:`}<ul><li>{`No - The visitor didn't use a mobile device to make the visit.`}</li><li>{`Yes - The visitor used a mobile device to make the visit.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search expression`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last expression that the visitor wrote in the search of the website. For example, `}<inlineCode parentName="td">{`creating a campaign`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average time that a visitor spends in the site in each visit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of contacts associated with the visitor. For example, `}<inlineCode parentName="td">{`4 contacts`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visitors`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of times a visitor accesses the site during a specified period.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      