import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Contacts`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}<br />{`subgroup_classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Sale?`}<br />{`field_classification_sales_is_sale_b`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}<br />{`subgroup_classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# Bundles`}<br />{`field_classification_sales_bundles_nr_i`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}<br />{`subgroup_classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# RGUS`}<br />{`field_classification_sales_rgus_nr_i`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}<br />{`subgroup_classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale value`}<br />{`field_classification_sales_total_value_f`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}<br />{`subgroup_classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification Date`}<br />{`field_typification_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has conversion?`}<br />{`field_contact_conversion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}<br />{`field_wrapup_code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`field_credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is it classified?`}<br />{`field_has_typification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause (contact center)`}<br />{`field_center_side_hangup_cause`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause`}<br />{`field_hangup_cause`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnection origin`}<br />{`field_disconnection_origin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}<br />{`subgroup_contact_finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}<br />{`field_contact_classification_status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue`}<br />{`field_contact_wait_time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact`}<br />{`field_total_wait_time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}<br />{`field_contact_duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact request`}<br />{`field_request_date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}<br />{`field_contact_date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of initial scheduling`}<br />{`field_initial_schedule_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup contact date`}<br />{`field_hangup_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}<br />{`field_contact_date_ymd`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}<br />{`field_contact_date_day`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}<br />{`field_contact_date_hour`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}<br />{`field_contact_date_week`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact`}<br />{`field_contact_date_weekday`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}<br />{`field_contact_year_yyyy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact (YYYYMM)`}<br />{`field_contact_year_month_yyyymm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of 1st attempt`}<br />{`field_first_attempt_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of success`}<br />{`field_call_connected_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted`}<br />{`field_wait_to_start_time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center)`}<br />{`field_customer_wait_time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}<br />{`subgroup_dates_and_times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration`}<br />{`field_contact_total_duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}<br />{`field_skill_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}<br />{`field_context_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact language`}<br />{`field_contact_language`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status per contact type`}<br />{`field_status_per_contact_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}<br />{`field_phone`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination`}<br />{`field_destination`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}<br />{`field_call_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}<br />{`field_operator_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}<br />{`field_operator_name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact channel`}<br />{`field_contact_channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request domain`}<br />{`field_contact_domain`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request IP`}<br />{`field_ip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}<br />{`field_organization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}<br />{`field_contact_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}<br />{`field_byside_cid`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact hangup origin`}<br />{`field_call_origin_hangup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}<br />{`field_operator_tags`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact initial skill`}<br />{`field_initial_branch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation reason`}<br />{`field_creation_reason`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Caller ID`}<br />{`field_caller_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Origin contact ID`}<br />{`field_origin_contact_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}<br />{`field_contact_status_binary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact subtype`}<br />{`field_contact_subtype`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}<br />{`field_contact_billing_status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction`}<br />{`field_contact_creation_direction`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source`}<br />{`field_contact_source`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sub status`}<br />{`field_contact_sub_status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}<br />{`field_time_on_node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia Main ID (Ticket ID)`}<br />{`field_ticket_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}<br />{`subgroup_location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}<br />{`field_isp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}<br />{`subgroup_location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}<br />{`field_country`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}<br />{`subgroup_location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}<br />{`field_region`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}<br />{`field_contact_date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact form`}<br />{`field_contact_form`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer review`}<br />{`field_customer_review`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form information`}<br />{`field_lead_ads`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}<br />{`subgroup_operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}<br />{`field_contact_operator_pickup_device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording`}<br />{`field_recording`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transcript`}<br />{`field_transcript`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human verified`}<br />{`field_human_verified`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has recording?`}<br />{`field_has_recording`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has cobrowse?`}<br />{`field_had_cobrowse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording On-demand`}<br />{`field_recording_on_demand`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Have on-demand recording?`}<br />{`field_has_on_demand_recording`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}<br />{`field_has_transcript`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Summary`}<br />{`field_contact_summary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`field_sentiment_analysis`}<br />{`field_sentiment_analysis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}<br />{`field_ai_classification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Bidireccional Video?`}<br />{`videocall_bidirectional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}<br />{`subgroup_other_services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Video?`}<br />{`videocall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Center number`}<br />{`field_callcenter_phone`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}<br />{`field_inbound_number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}<br />{`field_inbound_virtual_number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts`}<br />{`field_contact_num_attempts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator identification method`}<br />{`field_operator_identification_method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback canceled`}<br />{`field_callback_canceled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is short call?`}<br />{`field_is_short_call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction sender`}<br />{`field_last_message_sender`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction date`}<br />{`field_last_interaction_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has triggered a schedule?`}<br />{`field_scheduled_by_operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}<br />{`field_chat_num_interactions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`field_chat_num_nodes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}<br />{`field_chat_bot_name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}<br />{`subgroup_specific_by_contact_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice actions sequence`}<br />{`field_voice_actions_audios_sequence`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`IVR`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`field_ivr_duration_sec`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}<br />{`field_contact_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}<br />{`field_num_attempt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Name`}<br />{`field_ivr_name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}<br />{`field_skill_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwarded to`}<br />{`field_ivr_forward_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR start`}<br />{`field_ivr_start_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR end`}<br />{`field_ivr_end_datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last node`}<br />{`field_ivr_last_node_name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR status`}<br />{`field_ivr_status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total of processed nodes`}<br />{`field_ivr_total_processed_nodes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}<br />{`field_ivr_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR contact dates`}<br />{`field_ivr_contact_dates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTMF Success Rate`}<br />{`field_ivr_dtmf_success_rate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}<br />{`field_ivr_voice_recognition_accuracy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`field_ivr_trigger_point`}<br />{`field_ivr_trigger_point`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Leads`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}<br />{`field_phone`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}<br />{`subgroup_general`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}<br />{`field_organization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}<br />{`subgroup_location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}<br />{`field_isp`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visitors`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor record`}<br />{`field_visitor_form`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visits`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}<br />{`field_channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}<br />{`field_referrer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}<br />{`field_url`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start page`}<br />{`field_url_start`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit page`}<br />{`field_url_exit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}<br />{`field_segments_ids`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}<br />{`field_segments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}<br />{`field_referrer_simple_url`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact information`}<br />{`contact_context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}<br />{`field_simple_url`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      