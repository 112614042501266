import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ALL`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/content/"
                }}>{`Content`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/ivr/"
                }}>{`IVR`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/leads/"
                }}>{`Leads`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/visitors/"
                }}>{`Visitors`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/visits/"
                }}>{`Visits`}</a></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Categories`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content Feedback`}</p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Attributed conversion value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Attributed conversion value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Attributed conversion value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Attributed conversion value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Bot Name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Bot Name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Bounces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of visits where the visitor interacted with only one page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Callbacks canceled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Callbacks canceled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Callbacks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Callbacks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Classified contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Classified contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`DAvg`}{` `}{`Daily average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Daily average of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contacts with cobrowse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contacts with cobrowse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`DAvg`}{` `}{`Daily average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Daily average of Contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Credits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Forwardings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Has transcript?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Has transcript?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Impressions (baseline)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Impressions (with A/B variants)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Impressions (with profiles)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Impressions (with segments)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Impressions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Influenced conversions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Influenced conversions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Influenced conversions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Influenced conversions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Instances`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Leads`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Nodes used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Nodes used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Page requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of individual page requests that visitors make during a visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Pending Callbacks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Pending Callbacks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of RGUs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Sales`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Session duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Session duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Session duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Session duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Session duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Short calls`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Short calls`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Total contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Total contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unique sessions (with prints)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unique visitors (with prints)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unique visitors`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Visits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Wait time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_duration_sec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_duration_secAverage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_duration_sec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_duration_secSum`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CHAT`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Duration (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Live Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Live Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Nodes used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Nodes used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Nodes used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Number of interactions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Number of interactions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Number of interactions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Number of interactions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Time on node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Time on node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Time on node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Transfer Requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Transfer Requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Transfers with Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Transfers with Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Transfers without Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Transfers without Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess (Click2Chat)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess (Click2Chat)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTENT EVENT`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/content/"
              }}>{`Content`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Categories`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content Feedback`}</p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Detail Type (experience)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Detail Type (segmentation)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unique contents`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unique elements`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`FORM`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Form`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Form`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`STATE`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/leads/"
              }}>{`Leads`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Closed leads with sale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Closed leads with sale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Closed leads without sale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Closed leads without sale`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Closed leads`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Closed leads`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Open Leads`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Open Leads`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`VIDEO`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success (VideoCall)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success (VideoCall)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess (VideoCall)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess (VideoCall)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of VideoCall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of VideoCall`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`VOICE`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Abandonment on queue (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Abandonment on queue (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Abandonment on queue (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Abandonment on queue (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Abandonment on queue (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Abandonment on queue (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Call waiting time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Call-center did not answer (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Call-center did not answer (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Classified contacts (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Classified contacts (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Click2Call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Click2Call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Client did not answer (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Client did not answer (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Client did not answer (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Client did not answer (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contact center did not answer (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contact center did not answer (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contact center did not answer (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contact center did not answer (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Contact requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Credits (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Credits (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Credits (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Credits (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Credits (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Credits (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Credits (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Credits (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Credits (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Credits (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Credits (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Credits (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Credits (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Credits (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Credits (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Duration (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Duration (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Duration (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Human validation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Human validation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Identified contacts (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Identified contacts (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Invalid-Client (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Invalid-Client (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Invalid-Contact Center (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Invalid-Contact Center (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Short calls (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Short calls (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Short calls (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Short calls (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Success classified (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Success classified (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Time until call`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`.95`}{` `}{`95th percentile`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`M`}{` `}{`Maximum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`m`}{` `}{`Minimum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`µ`}{` `}{`Average`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`∑`}{` `}{`Sum`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of Total call duration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess (Inbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess (Outbound)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess-Silence (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess-Silence (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`#`}{` `}{`Count`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Count of Unsuccess-Voicemail (Click2Call)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`%`}{` `}{`Percentage`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of Unsuccess-Voicemail (Click2Call)`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      