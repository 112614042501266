import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Listing`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/listing-panel/"
        }}>{`Contacts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/listing-panel/"
        }}>{`IVR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/listing-panel/"
        }}>{`Leads`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/listing-panel/"
        }}>{`Visitors`}</a></li>
    </ul>
    <h2>{`Aggregation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/aggregation-panel-metrics/"
        }}>{`Contacts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/content/aggregation-panel-metrics/"
        }}>{`Content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/aggregation-panel-metrics/"
        }}>{`IVR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/aggregation-panel-metrics/"
        }}>{`Leads`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/aggregation-panel-metrics/"
        }}>{`Visitors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visits/aggregation-panel-metrics/"
        }}>{`Visits`}</a></li>
    </ul>
    <h2>{`Metric`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/contacts/metrics/"
        }}>{`Contacts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/content/metrics/"
        }}>{`Content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/ivr/metrics/"
        }}>{`IVR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/leads/metrics/"
        }}>{`Leads`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visitors/metrics/"
        }}>{`Visitors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/engagement-cloud/analytics/analytics-reference/visits/metrics/"
        }}>{`Visits`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      