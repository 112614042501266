import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`By label`}</h2>
    <ul>
      <li parentName="ul">{`Skill`}
        <ul parentName="li">
          <li parentName="ul">{`field_skill_id (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`field_skill_id (IVRsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Phone`}
        <ul parentName="li">
          <li parentName="ul">{`field_phone (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`field_phone (LeadManagementView-Contact information-General)`}</li>
          <li parentName="ul">{`field_phone (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Credits`}
        <ul parentName="li">
          <li parentName="ul">{`field_credits (ContactsView-Contact information-Contact finalization)`}</li>
          <li parentName="ul">{`metric_credits (ContactsView-All-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`External campaign`}
        <ul parentName="li">
          <li parentName="ul">{`field_external_campaign (ContactsView-Campaign information-None)`}</li>
          <li parentName="ul">{`field_external_campaign (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`External campaign type`}
        <ul parentName="li">
          <li parentName="ul">{`field_external_campaign_type (ContactsView-Campaign information-None)`}</li>
          <li parentName="ul">{`field_external_campaign_type (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Browser`}
        <ul parentName="li">
          <li parentName="ul">{`field_browser (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_browser (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_browser (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_browser (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device type`}
        <ul parentName="li">
          <li parentName="ul">{`field_device_type (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_type (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device model`}
        <ul parentName="li">
          <li parentName="ul">{`field_device_model (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_model (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_model (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_device_model (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device vendor`}
        <ul parentName="li">
          <li parentName="ul">{`field_device_vendor (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_vendor (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_vendor (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_device_vendor (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device family`}
        <ul parentName="li">
          <li parentName="ul">{`field_device_family (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_family (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_device_family (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_device_family (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device operating system`}
        <ul parentName="li">
          <li parentName="ul">{`field_platform (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_platform (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_platform (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Browser resolution`}
        <ul parentName="li">
          <li parentName="ul">{`field_resolution (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`field_resolution (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`field_resolution (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Cookie`}
        <ul parentName="li">
          <li parentName="ul">{`field_cookie (ContactsView-Customer information-Customer identification)`}</li>
          <li parentName="ul">{`field_cookie (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Referrer domain`}
        <ul parentName="li">
          <li parentName="ul">{`field_referrer_domain (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_referrer_domain (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Internet provider`}
        <ul parentName="li">
          <li parentName="ul">{`field_isp (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`field_isp (LeadManagementView-Contact information-Location information)`}</li>
          <li parentName="ul">{`field_isp (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_isp (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Organization`}
        <ul parentName="li">
          <li parentName="ul">{`field_organization (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`field_organization (LeadManagementView-Contact information-General)`}</li>
          <li parentName="ul">{`field_organization (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_organization (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Search terms`}
        <ul parentName="li">
          <li parentName="ul">{`field_search_expression (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_search_expression (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Referrer`}
        <ul parentName="li">
          <li parentName="ul">{`field_referrer (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_referrer (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_referrer (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`URL address`}
        <ul parentName="li">
          <li parentName="ul">{`field_url (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_url (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Country`}
        <ul parentName="li">
          <li parentName="ul">{`field_country (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`field_country (VisitorsView-Location information-None)`}</li>
          <li parentName="ul">{`field_country (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Region`}
        <ul parentName="li">
          <li parentName="ul">{`field_region (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`field_region (VisitorsView-Location information-None)`}</li>
          <li parentName="ul">{`field_region (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Location`}
        <ul parentName="li">
          <li parentName="ul">{`field_location (ContactsView-Location information-None)`}</li>
          <li parentName="ul">{`field_location (VisitorsView-Location information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Visitor info`}
        <ul parentName="li">
          <li parentName="ul">{`field_visitor_info (ContactsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_visitor_info (LeadManagementView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_visitor_info (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Device`}
        <ul parentName="li">
          <li parentName="ul">{`field_device (ContactsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_device (LeadManagementView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_device (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Date and time of contact`}
        <ul parentName="li">
          <li parentName="ul">{`field_contact_date (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`field_contact_date (ContactsView-Contact information-Dates and times)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Identification type`}
        <ul parentName="li">
          <li parentName="ul">{`field_identification_type (ContactsView-Customer information-Customer identification)`}</li>
          <li parentName="ul">{`field_identification_type (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Segment IDs`}
        <ul parentName="li">
          <li parentName="ul">{`field_segments_ids (ContactsView-Customer information-Segments)`}</li>
          <li parentName="ul">{`field_segments_ids (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_segments_ids (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Segments`}
        <ul parentName="li">
          <li parentName="ul">{`field_segments (ContactsView-Customer information-Segments)`}</li>
          <li parentName="ul">{`field_segments (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_segments (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Simplified referrer`}
        <ul parentName="li">
          <li parentName="ul">{`field_referrer_simple_url (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_referrer_simple_url (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Simplified URL`}
        <ul parentName="li">
          <li parentName="ul">{`field_simple_url (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`field_simple_url (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lead type`}
        <ul parentName="li">
          <li parentName="ul">{`field_lead_type_name (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`field_lead_type_name (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lead number`}
        <ul parentName="li">
          <li parentName="ul">{`field_lead_number (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`field_lead_number (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lead status`}
        <ul parentName="li">
          <li parentName="ul">{`field_lead_status (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`field_lead_status (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Number of interactions`}
        <ul parentName="li">
          <li parentName="ul">{`field_chat_num_interactions (ContactsView-Contact information-Specific by contact type)`}</li>
          <li parentName="ul">{`metric_contacts_num_interactions (ContactsView-Chat-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Nodes used`}
        <ul parentName="li">
          <li parentName="ul">{`field_chat_num_nodes (ContactsView-Contact information-Specific by contact type)`}</li>
          <li parentName="ul">{`metric_contacts_num_nodes (ContactsView-Chat-None)`}</li>
          <li parentName="ul">{`metric_total_processed_nodes (IVRsView-All-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Has transcript?`}
        <ul parentName="li">
          <li parentName="ul">{`field_has_transcript (ContactsView-Contact information-Other services)`}</li>
          <li parentName="ul">{`metric_has_transcript (ContactsView-All-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Time on node`}
        <ul parentName="li">
          <li parentName="ul">{`field_time_on_node (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`metric_contacts_time_on_node (ContactsView-Chat-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Contact requests`}
        <ul parentName="li">
          <li parentName="ul">{`metric_contacts_requested (ContactsView-All-None)`}</li>
          <li parentName="ul">{`metric_contacts_requested (ContactsView-Voice-None)`}</li>
          <li parentName="ul">{`metric_contacts_requested (ContactsView-Chat-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Duration`}
        <ul parentName="li">
          <li parentName="ul">{`metric_duration (ContactsView-All-None)`}</li>
          <li parentName="ul">{`field_ivr_duration_sec (IVRsView-Contact information-None)`}</li>
          <li parentName="ul">{`field_lead_duration (LeadManagementView-Lead information-None)`}</li>
          <li parentName="ul">{`metric_leadmanagement_duration (LeadManagementView-All-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Total contacts`}
        <ul parentName="li">
          <li parentName="ul">{`metric_leadmanagement_contacts_quantity_total (LeadManagementView-All-None)`}</li>
          <li parentName="ul">{`field_num_contacts (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Mobile device`}
        <ul parentName="li">
          <li parentName="ul">{`field_mobile (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`field_mobile (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unique visitors`}
        <ul parentName="li">
          <li parentName="ul">{`metric_visitors_unique (VisitorsView-All-None)`}</li>
          <li parentName="ul">{`metric_visitors_unique (VisitsView-All-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Session duration`}
        <ul parentName="li">
          <li parentName="ul">{`field_session_duration (VisitsView-Visitors information-None)`}</li>
          <li parentName="ul">{`metric_session_duration (VisitsView-All-None)`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <hr></hr>
    <hr></hr>
    <h2>{`By database name`}</h2>
    <ul>
      <li parentName="ul">{`field_skill_id`}
        <ul parentName="li">
          <li parentName="ul">{`Skill (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`Skill (IVRsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_phone`}
        <ul parentName="li">
          <li parentName="ul">{`Phone (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`Phone (LeadManagementView-Contact information-General)`}</li>
          <li parentName="ul">{`Phone (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_external_campaign`}
        <ul parentName="li">
          <li parentName="ul">{`External campaign (ContactsView-Campaign information-None)`}</li>
          <li parentName="ul">{`External campaign (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_external_campaign_type`}
        <ul parentName="li">
          <li parentName="ul">{`External campaign type (ContactsView-Campaign information-None)`}</li>
          <li parentName="ul">{`External campaign type (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_browser`}
        <ul parentName="li">
          <li parentName="ul">{`Browser (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Browser (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Browser (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`Browser (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Browser (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_device_type`}
        <ul parentName="li">
          <li parentName="ul">{`Device type (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device type (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_device_model`}
        <ul parentName="li">
          <li parentName="ul">{`Device model (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device model (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device model (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Device model (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_device_vendor`}
        <ul parentName="li">
          <li parentName="ul">{`Device vendor (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device vendor (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device vendor (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Device vendor (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_device_family`}
        <ul parentName="li">
          <li parentName="ul">{`Device family (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device family (LeadManagementView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device family (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Device family (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_platform`}
        <ul parentName="li">
          <li parentName="ul">{`Device operating system (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Device operating system (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Device operating system (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_resolution`}
        <ul parentName="li">
          <li parentName="ul">{`Browser resolution (ContactsView-Session information-Device information)`}</li>
          <li parentName="ul">{`Browser resolution (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Browser resolution (VisitorsView-Device information-None)`}</li>
          <li parentName="ul">{`Browser resolution (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_cookie`}
        <ul parentName="li">
          <li parentName="ul">{`Cookie (ContactsView-Customer information-Customer identification)`}</li>
          <li parentName="ul">{`Cookie (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_referrer_domain`}
        <ul parentName="li">
          <li parentName="ul">{`Referrer domain (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`Referrer domain (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Referrer domain (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_isp`}
        <ul parentName="li">
          <li parentName="ul">{`Internet provider (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`Internet provider (LeadManagementView-Contact information-Location information)`}</li>
          <li parentName="ul">{`Internet provider (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Internet provider (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_organization`}
        <ul parentName="li">
          <li parentName="ul">{`Organization (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`Organization (LeadManagementView-Contact information-General)`}</li>
          <li parentName="ul">{`Organization (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Organization (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_search_expression`}
        <ul parentName="li">
          <li parentName="ul">{`Search terms (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`Search terms (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Search terms (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_referrer`}
        <ul parentName="li">
          <li parentName="ul">{`Referrer (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`Referrer (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Referrer (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_url`}
        <ul parentName="li">
          <li parentName="ul">{`URL address (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`URL address (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`URL address (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_country`}
        <ul parentName="li">
          <li parentName="ul">{`Country (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`Country (ContactsView-Location information-None)`}</li>
          <li parentName="ul">{`Country (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Country (VisitorsView-Location information-None)`}</li>
          <li parentName="ul">{`Country (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_region`}
        <ul parentName="li">
          <li parentName="ul">{`Region (ContactsView-Contact information-Location information)`}</li>
          <li parentName="ul">{`Region (ContactsView-Location information-None)`}</li>
          <li parentName="ul">{`Region (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Region (VisitorsView-Location information-None)`}</li>
          <li parentName="ul">{`Region (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_location`}
        <ul parentName="li">
          <li parentName="ul">{`Location (ContactsView-Location information-None)`}</li>
          <li parentName="ul">{`Location (VisitorsView-Location information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_visitor_info`}
        <ul parentName="li">
          <li parentName="ul">{`Visitor info (ContactsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Visitor info (LeadManagementView-Visitors information-None)`}</li>
          <li parentName="ul">{`Visitor info (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_device`}
        <ul parentName="li">
          <li parentName="ul">{`Device (ContactsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Device (LeadManagementView-Visitors information-None)`}</li>
          <li parentName="ul">{`Device (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_contact_date`}
        <ul parentName="li">
          <li parentName="ul">{`Date and time of contact (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`Date and time of contact (ContactsView-Contact information-Dates and times)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_byside_vid`}
        <ul parentName="li">
          <li parentName="ul">{`Coremedia VID (ContactsView-Customer information-Customer identification)`}</li>
          <li parentName="ul">{`Coremedia VID (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`Coremedia VID (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_byside_cid`}
        <ul parentName="li">
          <li parentName="ul">{`Coremedia CID (ContactsView-Contact information-General)`}</li>
          <li parentName="ul">{`Coremedia CID (ContactsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_identification_type`}
        <ul parentName="li">
          <li parentName="ul">{`Identification type (ContactsView-Customer information-Customer identification)`}</li>
          <li parentName="ul">{`Identification type (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`Identification type (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_segments_ids`}
        <ul parentName="li">
          <li parentName="ul">{`Segment IDs (ContactsView-Customer information-Segments)`}</li>
          <li parentName="ul">{`Segment IDs (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Segment IDs (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_segments`}
        <ul parentName="li">
          <li parentName="ul">{`Segments (ContactsView-Customer information-Segments)`}</li>
          <li parentName="ul">{`Segments (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Segments (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_referrer_simple_url`}
        <ul parentName="li">
          <li parentName="ul">{`Simplified referrer (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`Simplified referrer (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Simplified referrer (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_simple_url`}
        <ul parentName="li">
          <li parentName="ul">{`Simplified URL (ContactsView-Session information-General)`}</li>
          <li parentName="ul">{`Simplified URL (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Simplified URL (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_lead_type_name`}
        <ul parentName="li">
          <li parentName="ul">{`Lead type (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`Lead type (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_lead_number`}
        <ul parentName="li">
          <li parentName="ul">{`Lead number (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`Lead number (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_lead_status`}
        <ul parentName="li">
          <li parentName="ul">{`Lead status (ContactsView-Lead information-None)`}</li>
          <li parentName="ul">{`Lead status (LeadManagementView-Lead information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`metric_contacts_requested`}
        <ul parentName="li">
          <li parentName="ul">{`Contact requests (ContactsView-All-None)`}</li>
          <li parentName="ul">{`Contact requests (ContactsView-Voice-None)`}</li>
          <li parentName="ul">{`Contact requests (ContactsView-Chat-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_channel`}
        <ul parentName="li">
          <li parentName="ul">{`Channel (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`Channel (VisitsView-Contact information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_fid`}
        <ul parentName="li">
          <li parentName="ul">{`Fid (ContactsView-Contact information-None)`}</li>
          <li parentName="ul">{`Fid (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_domain`}
        <ul parentName="li">
          <li parentName="ul">{`Domain (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Domain (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_remote_ip`}
        <ul parentName="li">
          <li parentName="ul">{`Remote IP (ContentFeedbackView-Visit-None)`}</li>
          <li parentName="ul">{`Remote IP (VisitorsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_mobile`}
        <ul parentName="li">
          <li parentName="ul">{`Mobile device (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`Mobile device (VisitsView-Visitors information-None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`field_id`}
        <ul parentName="li">
          <li parentName="ul">{`ID (VisitorsView-Visitors information-None)`}</li>
          <li parentName="ul">{`ID (VisitorsView--None)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`metric_visitors_unique`}
        <ul parentName="li">
          <li parentName="ul">{`Unique visitors (VisitorsView-All-None)`}</li>
          <li parentName="ul">{`Unique visitors (VisitsView-All-None)`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      