import axios from 'axios'
import qs from 'qs'

// JSON request options
const axiosJsonConfig = { headers: { 'Content-type': 'application/json' }, crossDomain: true };

// Enable this timeout do debug loading animations
const TIMEOUT_IN_MS = 0; // Disabled

export const DEFAULT_AMOUNT_OF_ROWS = 10;

export const fetchSolr = function({ query, facets = [], page= 0, justFacets = false }) {

  //const solrUrl = process.env.GATSBY_SOLR_URL || '/solr/cmcc-10/select';
  //URL of test API Gateway on moebius-xlabs
 //const solrUrl = 'https://ue2pui4g5b.execute-api.eu-west-1.amazonaws.com/beta'
  //URL of prod API Gateway on moebius-datacenter
  //const solrUrl = 'https://7u1vty9eq5.execute-api.eu-central-1.amazonaws.com/prod'
 // Keine Ahnung wovon das die URL ist
  //const solrUrl  = 'https://lp9kkuqhia.execute-api.eu-central-1.amazonaws.com/docusearch'

  const solrUrl = process.env.GATSBY_SOLR_URL || 'https://7u1vty9eq5.execute-api.eu-central-1.amazonaws.com/prod';

  // Group facets
  const groups = { module: [], book: [], version: [], doctype: [], distribution:[] }
  facets.forEach((facet) => { const parts = facet.split(':'); groups[parts[0]].push(facet) })
  const orGroups = !justFacets
    ? Object.keys(groups).map((key) => groups[key].join(' ')).filter((or) => or !== '')
    : []
  for (var i=0; i< orGroups.length; i++) {
    orGroups[i] = orGroups[i].replaceAll('"', "'");
    if (orGroups[i].indexOf(' ') > 0) {
      orGroups[i] = "(or " + orGroups[i] + ")";
    }
  }

  const oneGroup = orGroups.join(' ');
  //Removed url:'index.html' from filterSomeFiles because this removes the errorcodes document from search results
  //I think most of the API index.html files have been renamed to something like allclasses.html
  //When title is empty, CloudSearch sets "null" as a default value. This filters the seed-*.html files from the search results

  const filterSomeFiles = "(not (or url:'FileMap.html' url:'allclasses.html' title:'null'))"
  const fq = ["(and " + oneGroup + filterSomeFiles + ")"];
  const start = DEFAULT_AMOUNT_OF_ROWS * page;

  const params = {
    facet: true,
    q: query,
    'highlight.content': '{max_phrases:2}',
    'highlight.title': '{}',
    terms: {
      [query]: 5.0,
    },
  // hl: true,
  //  'hl.fragsize': 200,
  //  'hl.snippets': 2,
  //  'hl.mergeContiguous': true,
  //  'hl.alternateField': 'content',
  //  'hl.maxAlternateFieldLength': 300,
  //  'facet.field': ['module', 'book', 'version', 'doctype', 'distribution' ],
  //  'facet.limit': 400,
  //  'facet.mincount': justFacets ? 0 : 1,
  //  sort: 'score desc',
  //  rows: !justFacets ? DEFAULT_AMOUNT_OF_ROWS : 0,
      start: start,
  //  'json.nl': 'map',
    'facet.module': '{}',
    'facet.book': '{size:100}',
    'facet.version': '{size:100}',
    'facet.doctype': '{}',
    'facet.distribution': '{size:100}',
    'q.options':'{fields:[\'title^2\',\'content^1\']}',
    fq: fq
  //  wt: 'json'
  }

  const queryString = qs.stringify(params, { indices: false });

  return axios.get(`${solrUrl}?${queryString}`, axiosJsonConfig)
    .then(response => {
      if (process.env.NODE_ENV !== 'production') {
        return new Promise(resolve => setTimeout(() => resolve(response), TIMEOUT_IN_MS));
      }
      return new Promise(resolve => resolve(response));
    });
}

/**
 * Necessary because of a bug in AWS CloudSearch. Highlighting does not work in title when there is a dot after the
 * chapter number (and some more strange behavior). This function is a workaround to highlight the search term in the
 * title.
 *
 * @param The highlighted content text returned by AWS CloudSearch
 * @param The not highlighted normal title text returned by AWS CloudSearch
 * @param The search term
 * @returns A string with the highlighted title text
 */
export const surroundTextWithEm = function (contentText, titleText, searchTerm) {
  // Decode HTML-encoded string
  const decodedString = contentText.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

  // Find all text within <em> tags in the decoded string
  const matchResults = decodedString.match(/<em>(.*?)<\/em>/gi) || [];
  let textsToSurround = matchResults.map(match => match.replace(/<\/?em>/gi, ''));
  //Sometimes the search term does not appear in the content text, but in the title text. Therefore, we add the search term
  //to the texts to surround.
  textsToSurround.push(searchTerm.toLowerCase());


  if (textsToSurround.length > 0) {
    // Create a regular expression pattern to match all occurrences of the terms
    const pattern = new RegExp(textsToSurround.map(term => `(${term})`).join('|'), 'gi');

    // Surround all occurrences of the text in the second parameter with a single pair of <em> tags
    let surroundedText = titleText.replace(pattern, match => `<em>${match}</em>`);

    // Encode only spaces
    //const encodedSurroundedText = surroundedText.replace(/ /g, '&#32;');
    const encodedSurroundedText = surroundedText;
    return encodedSurroundedText;
  } else {
    // If no text is found within <em> tags, return the original second parameter
    return titleText;
  }
}
