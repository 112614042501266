import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the metrics of Leads aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Duration`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Duration`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Leads`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Total contacts`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Total contacts`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`State`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Closed leads`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Closed leads`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Closed leads with sale`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Closed leads with sale`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Closed leads without sale`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Closed leads without sale`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Open Leads`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of Open Leads`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      