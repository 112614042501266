import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <p>{`Before you start contact Support and Operations team and request:`}</p>
    <ul>
      <li parentName="ul">{`The creation of a Contact Center and Analytics Studio Instance for your site.`}</li>
      <li parentName="ul">{`The creation of a Chatbot and Automation Studio Instance for your site.`}</li>
      <li parentName="ul">{`The creation of users in both instances.`}</li>
      <li parentName="ul">{`Integration of the Chatbot and Automation Studio with Engagement Cloud using your Contact Center and Analytics Studio Instance and an API token.`}</li>
      <li parentName="ul">{`Add the CoreMedia Tag to your website.`}</li>
    </ul>
    <h2>{`Steps`}</h2>
    <ol>
      <li parentName="ol">{`In your Chatbot and Automation Studio Instance, create, save, and publish a chatbot. For example, create a chatbot with name `}<inlineCode parentName="li">{`greetings-chatbot`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`In the Contact Center and Analytics Studio, proceed as follows:`}
        <ol parentName="li">
          <li parentName="ol">{`In app Teams, create a team.`}</li>
          <li parentName="ol">{`Activate skill type `}<inlineCode parentName="li">{`Chat`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Create a skill of type `}<inlineCode parentName="li">{`Chat`}</inlineCode>{`. For example, `}<inlineCode parentName="li">{`Kio Skill`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`In the created skill, add the chatbot that you created and published in your Kio Instance. For example, `}<inlineCode parentName="li">{`greetings-chatbot`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Add the operation hours of the skill. For example, From Sunday to Saturday, from 00h00 to 23h59.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In the Engagement Studio, proceed as follows:`}
        <ol parentName="li">
          <li parentName="ol">{`Create an Onsite campaign. Go with the defaults.`}</li>
          <li parentName="ol">{`In the active elements tab, add a placeholder.`}</li>
          <li parentName="ol">{`In the placeholder, add an active element of type smart window.`}</li>
          <li parentName="ol">{`Configure the smart window to have:`}
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Type of service`}</em>{`: `}<inlineCode parentName="li">{`Chatbot`}</inlineCode></li>
              <li parentName="ul"><em parentName="li">{`Skill`}</em>{`: `}<inlineCode parentName="li">{`Kio Skill`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click the `}<em parentName="li">{`Accept`}</em>{` button.`}</li>
          <li parentName="ol">{`Activate the placeholder.`}</li>
          <li parentName="ol">{`Activate the smart window element.`}</li>
          <li parentName="ol">{`Activate the campaign.`}</li>
          <li parentName="ol">{`Click `}<em parentName="li">{`Save campaign`}</em>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Reload your site.`}</li>
    </ol>
    <h2>{`Result`}</h2>
    <p>{`After reloading, you should see Kio icon in your site.`}</p>
    <blockquote>
      <p parentName="blockquote">{`To remove the chatbot from your website, just deactivate the onsite campaign.
If you don't define the working hours in the skill, the chatbot will not appear active.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      