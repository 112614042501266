import {wrapRootElement as rootWrapper, wrapPageElement as pageWrapper} from './root-wrapper'
import { setConfiguration } from 'react-grid-system';
import { initialize } from './src/utils/zendesk'
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import './src/fancybox_custom.css'

// Wraps the root element in a component
export const wrapRootElement = rootWrapper;

// Wraps each page element into a component
export const wrapPageElement = pageWrapper;

export const onInitialClientRender = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.engagement.coremedia.cloud/cmec_we2.js';
  script.type = 'text/javascript';
  document.head.appendChild(script);
};

const addScript = ({ url, dataset = {}, defer = false, id }) => {
  const script = document.createElement("script")
  script.src = url
  if (!defer) {
    script.async = true
  } else {
    script.defer = true
  }
  script.type = "text/javascript"

  if (id) {
    script.id = id
  }

  Object.keys(dataset).forEach((key) => {
    script.dataset[key] = dataset[key]
  })
  document.body.appendChild(script)
}


export const onClientEntry = () => {

  // Initialise Cookie Consent & Zendesk
  window.onload = () => {
    addScript({
      url: "https://consent.cookiebot.com/uc.js",
      dataset: { cbid: "2f0bde22-a848-45b4-9af6-d69e0649d7a6" },
      id: "Cookiebot"
    })

    addScript({
      url: "https://static.zdassets.com/ekr/snippet.js?key=b08139c0-1357-4d46-a84f-bd77953de9f0",
      id: 'ze-snippet',
      defer: true
    })

    // Initialize Zendesk
    initialize()

    // Adding the script that sets the variables
    const inlineScript = document.createElement("script");
    inlineScript.text = `
  var bysideWebcare_webcare_id = "A867C149D0";
  var bysideWebcare_lang = "en";
`;
    document.body.appendChild(inlineScript);

    addScript({
      url: "https://cdn.engagement.coremedia.cloud/cmec_we2.js",
      defer: true
    });

  }

  // Set isOutsideEU dimension
  const isOutsideEU = typeof window.Cookiebot !== "undefined" ? (window.Cookiebot.isOutsideEU ? "non-EU" : "EU") : null;
  if (window.gtag && isOutsideEU) {
    window.gtag('set', 'dimension16', isOutsideEU);
  }

  // Set grid configuration
  setConfiguration({
    maxScreenClass: 'xl',
    containerWidths: [ 740, 740, 960, 1140, 1540 ]
  });
}

export const onRouteUpdate = () => {
  // Select all AsciiDoc-generated images wrapped in anchor tags
  const images = document.querySelectorAll("a:has(img)");

  images.forEach(image => {
    // Add data-fancybox attribute to make images compatible with Fancybox
    image.setAttribute("data-fancybox","gallery");
  });
  // Initialize Fancybox for newly added images
  Fancybox.bind("[data-fancybox]", {

  });
};
