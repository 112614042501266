import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <ul>
      <li parentName="ul">{`Credits`}
        <ul parentName="li">
          <li parentName="ul">{`field_credits`}</li>
          <li parentName="ul">{`metric_credits`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Number of interactions`}
        <ul parentName="li">
          <li parentName="ul">{`field_chat_num_interactions`}</li>
          <li parentName="ul">{`metric_contacts_num_interactions`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Nodes used`}
        <ul parentName="li">
          <li parentName="ul">{`field_chat_num_nodes`}</li>
          <li parentName="ul">{`metric_contacts_num_nodes (ContactsView - Metrics - % and #)`}</li>
          <li parentName="ul">{`metric_total_processed_nodes (IVRsView - Metrics and Aggregating Fields - Sum and Average)`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Has transcript?`}
        <ul parentName="li">
          <li parentName="ul">{`field_has_transcript`}</li>
          <li parentName="ul">{`metric_has_transcript`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Time on node`}
        <ul parentName="li">
          <li parentName="ul">{`field_time_on_node`}</li>
          <li parentName="ul">{`metric_contacts_time_on_node`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Duration`}
        <ul parentName="li">
          <li parentName="ul">{`metric_duration`}</li>
          <li parentName="ul">{`field_ivr_duration_sec`}</li>
          <li parentName="ul">{`field_lead_duration`}</li>
          <li parentName="ul">{`metric_leadmanagement_duration`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Total contacts`}
        <ul parentName="li">
          <li parentName="ul">{`metric_leadmanagement_contacts_quantity_total`}</li>
          <li parentName="ul">{`field_num_contacts`}</li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Session duration`}
        <ul parentName="li">
          <li parentName="ul">{`field_session_duration`}</li>
          <li parentName="ul">{`metric_session_duration`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      