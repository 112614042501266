import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Categories`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Customer identification(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(IVRsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Customer identification(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`CONTACT INFORMATION > Location information(LeadManagementView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`CONTACT INFORMATION > General(LeadManagementView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`CONTACT INFORMATION > General(LeadManagementView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SESSION INFORMATION > General(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Segments(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Segments(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(IVRsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      