import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the metrics of Visits aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Bounces`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of visits where the visitor interacted with only one page.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Page requests`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of individual page requests that visitors make during a visit.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b>{`: 95th percentile of Session duration`}</li><li><b>{`µ - Average`}</b>{`: Average of Session duration`}</li><li><b>{`M - Maximum`}</b>{`: Maximum of Session duration`}</li><li><b>{`m - Minimum`}</b>{`: Minimum of Session duration`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Session duration`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Unique visitors`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Visits`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      