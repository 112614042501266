import React from 'react'
import { graphql } from 'gatsby'
import ContainerWrapper from '../../components/ContainerWrapper'
import { Col, Row } from 'react-grid-system'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import dayjs from 'dayjs'
import styled from 'styled-components'
import usePrism from '../../hooks/use-prism'
import Seo from '../../components/SEO'
import { Rss, Link2 } from 'react-feather'
import { headlineToId } from '../../utils/links'
import { scroller } from 'react-scroll'
import useIsUserLoggedin from '../../hooks/use-is-user-logged-in'
import { mergeAndSortChangelogAndReleases, releaseToTitle} from '../../utils/changelog'
import { useLocation } from '@gatsbyjs/reach-router'
import Tag from '../../components/Tag'
import Filter from '../../components/Filter'
import {getAllValues} from "../../utils/versions";
import {useReducer, useEffect, useState} from "react";
import DateFilter from "../../components/DateFilter";
import {isDateInRange} from "../../utils/dates";
import {navigate} from "gatsby";

const LogArticle = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 1rem;
    a, a:visited {
      display: flex;
      align-items: center;
      color: #3f3f3f;
      text-decoration: none;
      font-weight: bold;

      svg {
        margin-left: 1rem;
        color: #CCCCCC;
      }
    }

    a:hover {
      content: '';
      text-decoration: underline;
      color: #3f3f3f;
      cursor: pointer;
      font-weight: bold;

      svg {
        color: #3f3f3f;
      }
    }
  }
`;

const EntryContainer = styled.div`
  display: flex;
  justify-content: space-between;


  @media (max-width: 1800px) {
    flex-direction: column;
  }
`;

const LeftDiv = styled.div`
  flex: 0 0 40%;
  margin-right: 1rem;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
    margin-right: 0;
  }
`;

const RightDiv = styled.div`
  flex: 0 0 60%;
  max-width: 70%;

  summary {
    /* Remove default list style and cursor */
    list-style: none;
    cursor: pointer;
    position: relative;
    padding-left: 20px; /* Space for the triangle */
    margin-bottom: 1rem;
    font-size: 100%;
    font-weight: bold;
  }

  summary::before {
    content: '\\25B6'; /* Unicode for the right-pointing triangle */
    position: absolute;
    left: 0;
    top: 25%;
    font-size: 0.8em;
    line-height: 1.0em;
    transition: transform 0.2s ease-in-out;
  }

  details[open] summary::before {
    content: '\\25BC'; /* Unicode for the down-pointing triangle */
  }

  pre {
    overflow-x: auto;         /* Enable horizontal scroll */
    white-space: pre;          /* Keep preformatted behavior */
    max-width: 100%;           /* Restrict width to container */
    box-sizing: border-box;
    background-color: #f7f7f7; /* Light grey background */
    padding-top: 36px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    margin-top: 24px;
    margin-right: -20px;
    margin-bottom: 24px;
    margin-left: -20px;
  }

  @media (max-width: 1000px) {
    flex: 0 0 100%;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 2rem;
`;

const Style = styled.div`

  .log {
    > div {
    }
  }

  p {
    font-size: 18px;
  }


  .meta {

    font-size: 14px;
    text-transform: uppercase;

    .date {
      margin-bottom: 24px;
      color: #787878;
      line-height: 150%;
    }

    .chips {

      margin-bottom: 12px;

      > div {
        display: inline-block;
        color: #ffffff;
        background-color: #006cae;
        border-radius: 24px;
        padding: 1px 12px;
      }

      > div + div {
        margin-left: 6px;
      }
    }
  }

  .rss-link {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    a {
      margin-left: 6px;
      text-decoration: underline;
      color: #006cae;
    }
  }

  .article {
    h2 {
      margin-top: 1rem;
      a, a:visited {
        display: flex;
        align-items: center;
        color: #3f3f3f;
        text-decoration: none;
        font-weight: bold;

        svg {
          margin-left: 1rem;
          color: #CCCCCC;
        }
      }

      a:hover {
        content: '';
        text-decoration: none;
        color: #3f3f3f;
        cursor: pointer;

        svg {
          color: #3f3f3f;
        }
      }
    }
  }

  .filter {
    margin-top: 0px;
    margin-bottom: 1rem;
    word-wrap: normal;

  }
`

const scrollerConfig = { smooth: true, offset: -130 }

const ColoredLine = ({ color }) => (
    <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 2,
          margin: "15px 0px 0px 0px",
        }}
    />
);

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMPONENTS_CMCC':
      return { ...state, componentsCMCC: action.payload};
    case 'SET_COMPONENTS_CMEC':
      return { ...state, componentsCMEC: action.payload};
    case 'SET_VERSIONS':
      return { ...state, versions: action.payload };
    case 'SET_CLOUD':
      return { ...state, cloud: action.payload };
    case 'SET_DATE_FILTER_RANGE':
      return { ...state, dateRange: action.payload };
    default:
      return state;
  }
};


const cloudNames = {all: 'CoreMedia Experience Platform', cmcc: 'CoreMedia Content Cloud', cmec: 'CoreMedia Engagement Cloud'}


const WhatsNewEntry = ({node}) => {
  const anchor = headlineToId(node.frontmatter.title)
  const container = React.useRef()
  usePrism(container)
  return (
    <div>
      <EntryContainer ref={container}>
        <LeftDiv>
          <h2 id={anchor} style={{display: 'flex', alignItems: 'center'}}>
            <a href={`#${anchor}`} onClick={() => scroller.scrollTo(anchor, scrollerConfig)}>
              {node.frontmatter.title}
              <Link2 style={{marginLeft: '0.5rem', flexShrink: 0}}/>
            </a>
          </h2>
          <div className={'meta'}>
            <div>
              {node.frontmatter.cloud && (
                <Tag tagValue={node.frontmatter.cloud} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>
              )}
              {node.frontmatter.version && node.frontmatter.version.split(',').map((version, index) => (
                <Tag key={index} tagValue={version.trim()} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>
              ))}
              {node.frontmatter.component && (
                <Tag tagValue={node.frontmatter.component} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>
              )}
            </div>
            <div className={'date'}>
              {dayjs(node.frontmatter.modified).format('D. MMMM, YYYY')}
            </div>
          </div>
        </LeftDiv>
        <RightDiv>
          <div style={{marginTop: '1rem'}}>
            <MDXRenderer>{node.body}</MDXRenderer>
          </div>
        </RightDiv>
      </EntryContainer>
      <ColoredLine color="#CCC"/>
    </div>
  )
}


const Child = ({hash, resetHash}) => {
  //("The hash is: ", hash)
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        console.log("Now scrolling to the hash")
        scroller.scrollTo(hash, scrollerConfig)
      }, 0);
    }
  }, [hash, resetHash])
  return '';
}

const WhatsNew = ({pageContext, data}) => {

  const initialFilterState = {
    componentsCMCC: [],
    componentsCMEC: [],
    versions: [],
    cloud: 'cmcc',
    type: 'all',
    dateRange: []
  };
  const combinedEdges = React.useMemo(
    () => mergeAndSortChangelogAndReleases([],
      data.allMdx
    ),
    [data.allMdx]
  )
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const [filteredEdges, setFilteredData] = useState(combinedEdges);
  const [showChild, setShowChild] = useState(false);
  const [hash, setHash] = useState(location.hash.substr(1));
  const [isFilterReady, setIsFilterReady] = useState(false);
  const [isFilesFiltered, setIsFilesFiltered] = useState(false);
  const [allEffectsCompleted, setAllEffectsCompleted] = useState(false);
  const resetHash = () => setHash(null);

  const {
    breadcrumb: {crumbs},
  } = pageContext

  const handleFilterChange = (filterName, value) => {
    //console.log("Im in handleFilterChange and the value is: " + value + " and the filterName is: " + filterName)
    dispatch({ type: filterName, payload: value });
  };

  const filterComponentsCMCC = React.useMemo(() => getAllValues(data.allMdx, 'cloud', 'cmcc', 'component'), [data.allMdx]);
  const filterComponentsCMEC = React.useMemo(() => getAllValues(data.allMdx, 'cloud', 'cmec', 'component'), [data.allMdx]);

  const filterVersionsCMCC = React.useMemo(() => getAllValues(data.allMdx, 'cloud', 'cmcc', 'version'), [data.allMdx]);


  const componentMapping = {
    'cmcc': filterComponentsCMCC,
    'cmec': filterComponentsCMEC
  }

  // Effect to initialize the filterState from the URL on the first render
  useEffect(() => {
    if (!isInitialized) {
      const params = new URLSearchParams(location.search);
      const filter = params.get("filter");
      if (filter) {
        dispatch({type: 'SET_CLOUD', payload: filter});
      }
      setIsInitialized(true); // Mark as initialized after setting the state
    }
  }, [isInitialized, location.search]);

  useEffect(() => {
    // Update the URL's search parameter when filterState.cloud changes
    if (isInitialized && filterState.cloud !== '') {
      //console.log("I'm in the URL setting effect and I'm in the condition")
      const searchParams = new URLSearchParams();
      searchParams.set('filter', filterState.cloud);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
      setIsFilterReady(true);
    }
  }, [filterState.cloud, location.pathname, isInitialized]);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  useEffect(() => {
    if (isFilterReady && isFilesFiltered && showChild) {
      setAllEffectsCompleted(true);
    }
  }, [isFilterReady, showChild, isFilesFiltered]);

  //Effect to reset the hash value at the very end
  useEffect(() => {
    if (allEffectsCompleted) {
      //console.log("Now deleting the hash")
      resetHash();
    }
  }, [allEffectsCompleted]);

  useEffect(() => {
    const filtered = combinedEdges.filter(edge => {
      //return true

      if (filterState.cloud === 'cmcc') {
        //console.log("I'm in the cmcc filter")
        const cloudMatch = !edge.node.frontmatter || edge.node.frontmatter.cloud === 'cmcc' ? true : false;
        const typeMatch = (!edge.node.frontmatter && (filterState.type === 'all' || filterState.type === 'releases')) || (edge.node.frontmatter && (filterState.type === 'all' || filterState.type === 'changes')) ? true : false;
        const componentsMatch = filterState.componentsCMCC.length === 0 || !edge.node.frontmatter || filterState.componentsCMCC.includes(edge.node.frontmatter?.component) ? true : false;
        const componentsFrontmatterMatch = !(filterState.componentsCMCC.length === 0) && !edge.node.frontmatter;
        //const versionMatch = (filterState.versions.length === 0 || ( edge.node.frontmatter && filterState.versions.includes(edge.node.frontmatter.version))) ? true : false;
        const versionMatch = filterState.versions.length === 0 || (edge.node.frontmatter && edge.node.frontmatter.version.split(',').some(version => filterState.versions.includes(version.trim())));
        return cloudMatch && typeMatch && componentsMatch && versionMatch && !componentsFrontmatterMatch;
      } else if (filterState.cloud === 'cmec') {
        //console.log("I'm in the cmec filter")
        const cloudMatch = !edge.node.frontmatter || edge.node.frontmatter.cloud === 'cmcc' ? false : true;
        const componentsMatch = filterState.componentsCMEC.length === 0 || !edge.node.frontmatter || filterState.componentsCMEC.includes(edge.node.frontmatter?.component) ? true : false;
        const dateMatch = !edge.node.frontmatter || filterState.dateRange.length === 0 || isDateInRange(edge.node.frontmatter.releaseDate, filterState.dateRange) ? true : false;
        return cloudMatch && componentsMatch && dateMatch;
      } else {
        return true;
      }
    });
    setFilteredData(filtered);
    setIsFilesFiltered(true);
  }, [filterState, combinedEdges]);

  return (
    <ContainerWrapper crumbs={crumbs} fluid={true}>
      <Seo
        title={`Known Issues in ${cloudNames[filterState.cloud]}` }
        description={'Known-Issues in our releases'}
      />
      <Style>
        <Row>
          <Col xs={0} md={4} className={"filter"}  style={{ maxWidth: '25%' }}>
            <div className={'rss-link'}>
              <Rss/><a href={'/whats-new-rss.xml'}>Subscribe via RSS Feed</a>
            </div>
            <h3>Filters</h3>
            <FiltersContainer>
              {filterState.cloud === 'cmcc' && (
                <>
                  <div className={'filter'}>
                    <Filter tagValue="Components" filterTypes={componentMapping[filterState.cloud]}
                            changeFunction={handleFilterChange}
                            filterName='SET_COMPONENTS_CMCC'
                            initialValue={filterState.componentsCMCC}
                            inputType={"checkbox"}/>
                  </div>
                  <div className={'filter'}>
                    <Filter tagValue="Affected Versions" filterTypes={filterVersionsCMCC}
                            changeFunction={handleFilterChange}
                            filterName='SET_VERSIONS'
                            initialValue={filterState.versions}
                            inputType={"checkbox"}/>
                  </div>
                </>
              )}
              {filterState.cloud === 'cmec' && (
                <>
                  <div className={'filter'}>
                    <Filter tagValue="Components" filterTypes={componentMapping[filterState.cloud]}
                            changeFunction={handleFilterChange}
                            filterName='SET_COMPONENTS_CMEC'
                            initialValue={filterState.componentsCMEC}
                            inputType={"checkbox"}/>
                  </div>
                  <div className={'filter'}>
                    <DateFilter onFilterChange={handleFilterChange}/>
                  </div>
                </>
              )}

            </FiltersContainer>
          </Col>
          <Col xs={12} md={8}>

            <h1>Known Issues in the CoreMedia Experience Cloud</h1>
            <p>
              Find issues that have been identified in our releases. We provide information on the affected components, the affected version and the fixed versions.
              </p>
              <ColoredLine color="#CCC"/>
              <LogArticle>
                {filteredEdges.map(({node}, index) => {
                   return node.amp
                     ? ""
                     : <WhatsNewEntry node={node} key={index}/>;
                })}
              </LogArticle>
              {showChild && <Child hash={hash} resetHash={resetHash}/>}
          </Col>
          </Row>
      </Style>
    </ContainerWrapper>
  );
}

export const Head = () =>
  <link rel="alternate" type="application/rss+xml"
        title="CoreMedia Content Cloud - What's New RSS Feed"
        href="/whats-new-rss.xml"/>

export default WhatsNew

export const query = graphql`
  {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/.*\/knownissues\/.*/"}}
      sort: {fields: frontmatter___modified, order: DESC}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            modified
            component
            version
            cloud
            majorVersion
            releaseDate
          }
        }
      }
    }
  }
        `
