import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Contacts View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# Bundles`}<br />{`# RGUS`}<br />{`Classification Date`}<br />{`Has Sale?`}<br />{`Sale value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# Bundles`}<br />{`# RGUS`}<br />{`Classification Date`}<br />{`Has Sale?`}<br />{`Sale value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification date & time`}<br />{`Classification date `}{`[(YYYYMMDD)]`}<br />{`Classification date `}{`[Day (DD)]`}<br />{`Classification date `}{`[Hour (HH)]`}<br />{`Classification date `}{`[Month (MM)]`}<br />{`Classification date `}{`[Quarter]`}<br />{`Classification date `}{`[Week]`}<br />{`Classification date `}{`[Weekday]`}<br />{`Classification date `}{`[Year (YYYY)]`}<br />{`Classification date `}{`[Year and month (YYYYMM)]`}<br />{`Classification month and week and day`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact finalization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}<br />{`Credits`}<br />{`Disconnection origin`}<br />{`Hangup cause`}<br />{`Hangup cause (contact center)`}<br />{`Has conversion?`}<br />{`Is it classified?`}<br />{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}<br />{`Credits`}<br />{`Disconnection origin`}<br />{`Hangup cause`}<br />{`Hangup cause (contact center)`}<br />{`Has conversion?`}<br />{`Is it classified?`}<br />{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}<br />{`Credits`}<br />{`Disconnection origin`}<br />{`Hangup cause`}<br />{`Hangup cause (contact center)`}<br />{`Has conversion?`}<br />{`Is it classified?`}<br />{`Wrapup code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dates and times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}<br />{`Contact duration (in operation)`}<br />{`Contact total duration`}<br />{`Date and time of contact`}<br />{`Date of contact request`}<br />{`Date of initial scheduling`}<br />{`Day of contact (DD)`}<br />{`Hangup contact date`}<br />{`Hour of contact (HH)`}<br />{`Initial date of 1st attempt`}<br />{`Initial date of success`}<br />{`Wait time (until contact center)`}<br />{`Waiting time already in contact`}<br />{`Waiting time in queue`}<br />{`Waiting time until contacted`}<br />{`Week of contact`}<br />{`Weekday of contact`}<br />{`Year and month of contact (YYYYMM)`}<br />{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}<br />{`Contact total duration`}<br />{`Date and time of contact`}<br />{`Date of contact request`}<br />{`Date of initial scheduling`}<br />{`Hangup contact date`}<br />{`Initial date of 1st attempt`}<br />{`Initial date of success`}<br />{`Wait time (until contact center)`}<br />{`Waiting time already in contact`}<br />{`Waiting time in queue`}<br />{`Waiting time until contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}<br />{`Contact duration (in operation)`}<br />{`Date and time of contact`}<br />{`Day of contact (DD)`}<br />{`Hour of contact (HH)`}<br />{`Initial date of 1st attempt`}<br />{`Initial date of success`}<br />{`Wait time (until contact center)`}<br />{`Waiting time already in contact`}<br />{`Waiting time in queue`}<br />{`Waiting time until contacted`}<br />{`Week of contact`}<br />{`Weekday of contact`}<br />{`Year and month of contact (YYYYMM)`}<br />{`Year of contact (YYYY)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}<br />{`Caller ID`}<br />{`Contact channel`}<br />{`Contact hangup origin`}<br />{`Contact initial skill`}<br />{`Contact language`}<br />{`Contact request IP`}<br />{`Contact request domain`}<br />{`Contact status`}<br />{`Contact subtype`}<br />{`Contact type`}<br />{`Coremedia CID`}<br />{`Coremedia Main ID (Ticket ID)`}<br />{`Creation reason`}<br />{`Destination`}<br />{`Direction`}<br />{`Operator ID`}<br />{`Operator name`}<br />{`Operator tags`}<br />{`Organization`}<br />{`Origin contact ID`}<br />{`Phone`}<br />{`Skill`}<br />{`Source`}<br />{`Status per contact type`}<br />{`Sub status`}<br />{`Team`}<br />{`Time on node`}<br />{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}<br />{`Caller ID`}<br />{`Contact channel`}<br />{`Contact hangup origin`}<br />{`Contact initial skill`}<br />{`Contact language`}<br />{`Contact request IP`}<br />{`Contact status`}<br />{`Contact subtype`}<br />{`Contact type`}<br />{`Coremedia CID`}<br />{`Coremedia Main ID (Ticket ID)`}<br />{`Creation reason`}<br />{`Destination`}<br />{`Direction`}<br />{`Operator ID`}<br />{`Operator name`}<br />{`Operator tags`}<br />{`Organization`}<br />{`Origin contact ID`}<br />{`Phone`}<br />{`Skill`}<br />{`Source`}<br />{`Status per contact type`}<br />{`Sub status`}<br />{`Team`}<br />{`Time on node`}<br />{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}<br />{`Contact channel`}<br />{`Contact hangup origin`}<br />{`Contact initial skill`}<br />{`Contact language`}<br />{`Contact request domain`}<br />{`Contact status`}<br />{`Contact subtype`}<br />{`Contact type`}<br />{`Direction`}<br />{`Operator ID`}<br />{`Operator name`}<br />{`Operator tags`}<br />{`Organization`}<br />{`Skill`}<br />{`Source`}<br />{`Status per contact type`}<br />{`Sub status`}<br />{`Team`}<br />{`Time on node`}<br />{`Voice contact subtype`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}<br />{`Internet provider`}<br />{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}<br />{`Internet provider`}<br />{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}<br />{`Region`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`No Sub Category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact form`}<br />{`Customer review`}<br />{`Date and time of contact`}<br />{`Form information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}<br />{`Contact total duration (by intervals)`}<br />{`Coremedia CID`}<br />{`Coremedia VID`}<br />{`Duration (by intervals)`}<br />{`Fid`}<br />{`Has IVR?`}<br />{`IVR duration`}<br />{`Identification type`}<br />{`Last interaction date (YYYYMMDD)`}<br />{`Last interaction day (DD)`}<br />{`Last interaction day of the week`}<br />{`Last interaction hour (HH)`}<br />{`Last interaction month (MM)`}<br />{`Last interaction quarter`}<br />{`Last interaction week`}<br />{`Last interaction year (YYYY)`}<br />{`Last interaction year and month (YYYYMM)`}<br />{`Source chat`}<br />{`Target chat`}<br />{`Wait time (until contact center) (by intervals)`}<br />{`Waiting time already in contact (by intervals)`}<br />{`Waiting time in queue (by intervals)`}<br />{`Waiting time until contacted (by intervals)`}<br />{`Wrapup code description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration (by intervals)`}<br />{`Coremedia CID`}<br />{`Duration (by intervals)`}<br />{`Has IVR?`}<br />{`IVR duration`}<br />{`Last interaction date (YYYYMMDD)`}<br />{`Last interaction day (DD)`}<br />{`Last interaction day of the week`}<br />{`Last interaction hour (HH)`}<br />{`Last interaction month (MM)`}<br />{`Last interaction quarter`}<br />{`Last interaction week`}<br />{`Last interaction year (YYYY)`}<br />{`Last interaction year and month (YYYYMM)`}<br />{`Wait time (until contact center) (by intervals)`}<br />{`Waiting time already in contact (by intervals)`}<br />{`Waiting time in queue (by intervals)`}<br />{`Waiting time until contacted (by intervals)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Other services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}<br />{`Contact Summary`}<br />{`Has Bidireccional Video?`}<br />{`Has Video?`}<br />{`Has cobrowse?`}<br />{`Has recording?`}<br />{`Has transcript?`}<br />{`Have on-demand recording?`}<br />{`Human verified`}<br />{`Recording`}<br />{`Recording On-demand`}<br />{`Transcript`}<br />{`field_sentiment_analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}<br />{`Contact Summary`}<br />{`Has Bidireccional Video?`}<br />{`Has Video?`}<br />{`Has cobrowse?`}<br />{`Has recording?`}<br />{`Has transcript?`}<br />{`Have on-demand recording?`}<br />{`Human verified`}<br />{`Transcript`}<br />{`field_sentiment_analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Bidireccional Video?`}<br />{`Has Video?`}<br />{`Has cobrowse?`}<br />{`Has recording?`}<br />{`Has transcript?`}<br />{`Human verified`}<br />{`field_sentiment_analysis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific by contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}<br />{`Callback canceled`}<br />{`Contact Center number`}<br />{`Has triggered a schedule?`}<br />{`Inbound number`}<br />{`Is short call?`}<br />{`Last interaction date`}<br />{`Last interaction sender`}<br />{`Nodes used`}<br />{`Number of attempts`}<br />{`Number of interactions`}<br />{`Operator identification method`}<br />{`Virtual inbound number`}<br />{`Voice actions sequence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}<br />{`Callback canceled`}<br />{`Contact Center number`}<br />{`Has triggered a schedule?`}<br />{`Inbound number`}<br />{`Is short call?`}<br />{`Last interaction date`}<br />{`Last interaction sender`}<br />{`Nodes used`}<br />{`Number of attempts`}<br />{`Number of interactions`}<br />{`Operator identification method`}<br />{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}<br />{`Callback canceled`}<br />{`Contact Center number`}<br />{`Has triggered a schedule?`}<br />{`Inbound number`}<br />{`Last interaction date`}<br />{`Nodes used`}<br />{`Number of attempts`}<br />{`Operator identification method`}<br />{`Virtual inbound number`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`ContentFeedback View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
    </table>
    <h2>{`IVRs View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`No Sub Category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}<br />{`Contact ID`}<br />{`DTMF Success Rate`}<br />{`Duration`}<br />{`Forwarded to`}<br />{`IVR ID`}<br />{`IVR Name`}<br />{`IVR contact dates`}<br />{`IVR end`}<br />{`IVR start`}<br />{`IVR status`}<br />{`Last node`}<br />{`Skill`}<br />{`Total of processed nodes`}<br />{`Voice Recognition Accuracy`}<br />{`field_ivr_trigger_point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}<br />{`Contact ID`}<br />{`DTMF Success Rate`}<br />{`Duration`}<br />{`Forwarded to`}<br />{`IVR ID`}<br />{`IVR Name`}<br />{`IVR end`}<br />{`IVR start`}<br />{`IVR status`}<br />{`Last node`}<br />{`Skill`}<br />{`Total of processed nodes`}<br />{`Voice Recognition Accuracy`}<br />{`field_ivr_trigger_point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}<br />{`IVR Name`}<br />{`IVR start`}<br />{`IVR status`}<br />{`field_ivr_trigger_point`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`LeadManagement View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`General`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}<br />{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}<br />{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visitors View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`No Sub Category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor record`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visits View`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Listing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Rules`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Agg fields`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`No Sub Category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}<br />{`Exit page`}<br />{`Referrer`}<br />{`Segment IDs`}<br />{`Segments`}<br />{`Simplified URL`}<br />{`Simplified referrer`}<br />{`Start page`}<br />{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}<br />{`Exit page`}<br />{`Segment IDs`}<br />{`Segments`}<br />{`Simplified URL`}<br />{`Simplified referrer`}<br />{`Start page`}<br />{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit page`}<br />{`Segment IDs`}<br />{`Segments`}<br />{`Simplified URL`}<br />{`Simplified referrer`}<br />{`Start page`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      