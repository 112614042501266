import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the fields of Leads data source that you can use in rules, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Contact Information > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Location Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Lead Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`For IVRs, duration of the interaction in the IVR. If the IVR forwards the interaction to an operator, this duration doesn't include time spent with the operator.`}</li><li>{`For Leads, sum of the durations of all the contacts associated with the lead.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: If the lead had a sale.`}</li><li><strong parentName="td">{`No`}</strong>{`: If the lead did not had a sale.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the lead resulted in a sale, contains the date when the sale was completed.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Session Information > Device Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      