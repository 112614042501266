import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Contacts`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}<br />{`metric_onsite_campaigns_attributed_cost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}<br />{`metric_onsite_campaigns_attributed_cost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}<br />{`metric_onsite_campaigns_attributed_cost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}<br />{`metric_onsite_campaigns_attributed_cost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}<br />{`metric_contacts_bot_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}<br />{`metric_contacts_bot_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}<br />{`metric_callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}<br />{`metric_callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}<br />{`metric_callbacks_canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}<br />{`metric_callbacks_canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}<br />{`metric_contacts_typifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}<br />{`metric_contacts_typifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAvg - Daily average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}<br />{`metric_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}<br />{`metric_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}<br />{`metric_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAvg - Daily average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}<br />{`metric_contacts_cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}<br />{`metric_contacts_cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`metric_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`metric_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`metric_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`metric_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}<br />{`metric_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}<br />{`metric_has_transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}<br />{`metric_has_transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}<br />{`metric_onsite_campaigns_influenced_conversion_no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}<br />{`metric_onsite_campaigns_influenced_conversion_no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}<br />{`metric_onsite_campaigns_influenced_conversion_no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}<br />{`metric_onsite_campaigns_influenced_conversion_no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}<br />{`metric_callbacks_active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}<br />{`metric_callbacks_active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}<br />{`metric_rgus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}<br />{`metric_sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}<br />{`metric_is_short_call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}<br />{`metric_is_short_call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}<br />{`metric_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}<br />{`metric_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}<br />{`metric_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}<br />{`metric_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}<br />{`metric_contact_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}<br />{`metric_c2h_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}<br />{`metric_c2h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}<br />{`metric_c2h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`metric_contacts_num_nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`metric_contacts_num_nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`metric_contacts_num_nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}<br />{`metric_contacts_num_interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}<br />{`metric_contacts_num_interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}<br />{`metric_contacts_num_interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}<br />{`metric_contacts_num_interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}<br />{`metric_c2h_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}<br />{`metric_c2h_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}<br />{`metric_contacts_time_on_node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}<br />{`metric_contacts_time_on_node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}<br />{`metric_contacts_time_on_node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}<br />{`metric_c2h_transfers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}<br />{`metric_c2h_transfers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}<br />{`metric_c2h_transfers_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}<br />{`metric_c2h_transfers_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}<br />{`metric_c2h_transfers_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}<br />{`metric_c2h_transfers_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}<br />{`metric_c2h_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}<br />{`metric_c2h_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_lead_ads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}<br />{`metric_lead_ads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_lead_ads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}<br />{`metric_lead_ads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}<br />{`metric_v2c_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}<br />{`metric_v2c_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}<br />{`metric_v2c_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}<br />{`metric_v2c_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}<br />{`metric_v2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}<br />{`metric_v2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}<br />{`metric_c2c_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}<br />{`metric_c2c_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}<br />{`metric_call_inbound_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}<br />{`metric_call_inbound_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}<br />{`metric_call_outbound_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}<br />{`metric_call_outbound_abandon_queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}<br />{`metric_c2c_total_customer_wait_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}<br />{`metric_c2c_unanswered_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}<br />{`metric_c2c_unanswered_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}<br />{`metric_c2c_typifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}<br />{`metric_c2c_typifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}<br />{`metric_c2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}<br />{`metric_c2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}<br />{`metric_c2c_unanswered_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}<br />{`metric_c2c_unanswered_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}<br />{`metric_call_outbound_unsuccess_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}<br />{`metric_call_outbound_unsuccess_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}<br />{`metric_call_inbound_unsuccess_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}<br />{`metric_call_inbound_unsuccess_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}<br />{`metric_call_outbound_unsuccess_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}<br />{`metric_call_outbound_unsuccess_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}<br />{`metric_contacts_requested`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}<br />{`metric_c2c_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}<br />{`metric_c2c_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}<br />{`metric_c2c_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}<br />{`metric_c2c_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}<br />{`metric_c2c_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}<br />{`metric_call_inbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}<br />{`metric_call_inbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}<br />{`metric_call_inbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}<br />{`metric_call_inbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}<br />{`metric_call_inbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}<br />{`metric_call_outbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}<br />{`metric_call_outbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}<br />{`metric_call_outbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}<br />{`metric_call_outbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}<br />{`metric_call_outbound_credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}<br />{`metric_c2c_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}<br />{`metric_call_inbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}<br />{`metric_call_outbound_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}<br />{`metric_c2c_human_verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}<br />{`metric_c2c_human_verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}<br />{`metric_c2c_identified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}<br />{`metric_c2c_identified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}<br />{`metric_call_inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}<br />{`metric_call_inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}<br />{`metric_c2c_invalid_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}<br />{`metric_c2c_invalid_cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}<br />{`metric_c2c_invalid_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}<br />{`metric_c2c_invalid_cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}<br />{`metric_call_outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}<br />{`metric_call_outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}<br />{`metric_is_short_call_c2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}<br />{`metric_is_short_call_c2c`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}<br />{`metric_is_short_call_inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}<br />{`metric_is_short_call_inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}<br />{`metric_c2c_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}<br />{`metric_c2c_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}<br />{`metric_call_inbound_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}<br />{`metric_call_inbound_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}<br />{`metric_call_outbound_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}<br />{`metric_call_outbound_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}<br />{`metric_c2c_success_classified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}<br />{`metric_c2c_success_classified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}<br />{`metric_wait_to_start_time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}<br />{`metric_contact_total_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}<br />{`metric_c2c_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}<br />{`metric_c2c_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}<br />{`metric_call_inbound_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}<br />{`metric_call_inbound_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}<br />{`metric_call_outbound_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}<br />{`metric_call_outbound_unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}<br />{`metric_c2c_unsuccess_silence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}<br />{`metric_c2c_unsuccess_silence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}<br />{`metric_c2c_unsuccess_voicemail`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}<br />{`ag_contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_contacts_voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}<br />{`metric_c2c_unsuccess_voicemail`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Content`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}<br />{`metric_impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline)`}<br />{`metric_impressions_baseline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants)`}<br />{`metric_impressions_with_variants`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles)`}<br />{`metric_impressions_with_profiles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments)`}<br />{`metric_impressions_with_segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints)`}<br />{`metric_content_sessions_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints)`}<br />{`metric_content_visitors_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}<br />{`content_event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience)`}<br />{`metric_content_detail_type_experience`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}<br />{`content_event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation)`}<br />{`metric_content_detail_type_segmentation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}<br />{`content_event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents`}<br />{`metric_content_contents_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback`}<br />{`ag_content_feedback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Event`}<br />{`content_event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements`}<br />{`metric_content_cmelements_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`IVR`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (s)`}<br />{`metric_duration_sec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (s)`}<br />{`metric_duration_sec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}<br />{`metric_forward_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}<br />{`metric_total_instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`metric_total_processed_nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_ivr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}<br />{`metric_total_processed_nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Leads`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_leadmanagement_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}<br />{`metric_leadmanagement_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads`}<br />{`metric_leadmanagement_leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}<br />{`metric_leadmanagement_contacts_quantity_total`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}<br />{`metric_leadmanagement_contacts_quantity_total`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}<br />{`metric_leadmanagement_leads_state_closed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}<br />{`metric_leadmanagement_leads_state_closed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}<br />{`metric_leadmanagement_leads_with_sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}<br />{`metric_leadmanagement_leads_with_sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}<br />{`metric_leadmanagement_closed_leads_without_sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}<br />{`metric_leadmanagement_closed_leads_without_sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}<br />{`metric_leadmanagement_leads_state_open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}<br />{`ag_leadmanagement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_leads_state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}<br />{`metric_leadmanagement_leads_state_open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visitors`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}<br />{`metric_visitors_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visits`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Label`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}<br />{`metric_visits_bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}<br />{`metric_visits_bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}<br />{`metric_pages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}<br />{`metric_pages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`% - Percentage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}<br />{`metric_session_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑ - Sum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}<br />{`metric_session_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ - Average`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}<br />{`metric_session_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m - Minimum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}<br />{`metric_session_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M - Maximum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}<br />{`metric_session_duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95 - 95th percentile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}<br />{`metric_visitors_unique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}<br />{`ag_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}<br />{`mk_all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}<br />{`metric_visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`# - Count`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      