import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the metrics of IVR aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Forwardings`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of Instances`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: Average of Nodes used`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of Nodes used`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_dtmf_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of metric_dtmf_success`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of metric_dtmf_success`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of metric_dtmf_success`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_drop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of metric_ivr_drop`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of metric_ivr_drop`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_duration_sec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b>{`: metric_ivr_duration_secAverage`}</li><li><b>{`∑ - Sum`}</b>{`: metric_ivr_duration_secSum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_voice_recognition_success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of metric_voice_recognition_success`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of metric_voice_recognition_success`}</li><li><b>{`∑ - Sum`}</b>{`: Sum of metric_voice_recognition_success`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Trigger Point`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Beginning of the call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Beginning of the callCount`}</li><li><b>{`% - Percentage`}</b>{`: Beginning of the callPercentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End of the call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: End of the callCount`}</li><li><b>{`% - Percentage`}</b>{`: End of the callPercentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mid-call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b>{`: Count of mid-call`}</li><li><b>{`% - Percentage`}</b>{`: Percentage of mid-call`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      